/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '@angular/material/theming';

@include mat.core();

html, body { 
    height: 100%;
}

body {
    color: black;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    margin: 0px;
}

table, th, td {
  border: 1px solid;
}

label {
  white-space: pre-wrap !important;
  word-break: break-word !important;
}

h2 {
  white-space: pre-wrap !important;
  word-break: break-word !important;
}

h3 {
  white-space: pre-wrap !important;
  word-break: break-word !important;
}

.light-theme {
    $primary: mat.define-palette(mat.$indigo-palette);
    $accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
    $warn: mat.define-palette(mat.$red-palette);
    $light-theme: mat.define-light-theme($primary, $accent, $warn);
  
    .mat-expansion-panel {
      background-color: rgba(238,238,238, 0.5) !important;
    }
  
    .mat-expansion-panel.expansion-panel-light {
      background-color: #C0C0C0 !important;
    }
  
    .mat-expansion-panel-header.expantion-panel-light {
      background-color: #C0C0C0 !important;
    }
  
    .mat-expansion-panel.expansion-panel-darker {
      background-color: #A0A0A0 !important;
    }
  
    .mat-expansion-panel-header.expansion-panel-darker {
      background-color: #A0A0A0 !important;
    }
  
    .warning-background {
      background-color: #fc1a1a !important;
    }

    .mat-raised-button.mat-button-disabled {
        color:#C0C0C0 !important;
        background-color: #2d8622 !important;
        font-weight: bold !important;
    }
  
    .mat-raised-button {
        color:white !important;
        background-color: #2d8622 !important;
        font-weight: bold !important;
    }

    .mat-progress-spinner circle {
      stroke: #2d8622 !important;
    }

    .warning {
      color: #EB0000;
    }
  
    .loading {
      color: blue;
    }
  
    .success {
      color: green
    }
  
    .theme-background {
      background-color: rgba(238,238,238, 0.5) !important;
    }
  
    .snackbar-success {
      background-color: white !important;
    }
  
    @include mat.all-component-themes($light-theme);
  }